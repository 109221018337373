
.we-specialize-in-container{
  background-color: #12408c;
  color: white ;
}
.column-1{
  border-right: 2px solid white;
}
.column-2{
  border-right: 2px solid white;
}
/* hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 13px solid rgba(0, 0, 0, 0.1);
    border: 1px solid red;

  } */
  .hr-top{
    width: 5%;
    margin-right: 0.7em;
  }
  .hr-bottom{
    width: 5%;
    margin-left: 0.7em;
  }
  .growth-logo-span{
    padding-right: 12px;
  }
  .growth-logo{
    height: 36px;
    width:50vh;
    margin-left:auto;
    margin-right:auto;

    
  }
  .column-3{
    border: 0;
    /* border-top: 2px solid white; */
    /* border-right: 2px solid white; */
  }
  .column-4{
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  .column-5{
    border-top: 2px solid white;
    border-right: 2px solid white;
  }
  .column-6{
    border-top: 2px solid white;
    /* border-right: 2px solid white; */
  }
  @media (max-width:991px) and (min-width:768px){
    .column-1{
      border-right: 0px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
    }
    .column-3{
      /* border-top: 2px solid white; */
      border-right: 2px solid white;
    }
    .column-2{
      border-right: 0px;
      border-bottom: 2px solid white;

    }
    .column-4{
      border-top: 0;
      border-right: 0;
    }
  }
  @media (max-width:768px){
    .column-1{
      border-right: 0px;
      border-bottom: 2px solid white;
      /* border-right: 2px solid white; */
    }
    .column-2{
      border-right: 0px;
      border-bottom: 2px solid white;
    }
    .column-3{
      /* border-top: 2px solid white; */
      border-bottom: 2px solid white;
    }
    .column-4{
      border-top: 0;
      border-right: 0;
    }
    .column-5{
      /* border-top: 0; */
      border-right: 0;
      border-bottom: 2px solid white;
    }.column-6{
      border-top: 0;
      /* border-right: 2px solid white; */
    }
  }
/* 991 768 */