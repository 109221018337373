/* .box-one {
  position: relative;
}
.layer {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin: auto;
  text-align: center;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
} */
/* .box-two {
  width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: height 1s;
  background-color: rgba(0, 66, 186, 0.8);
  overflow: hidden;
} */
/* .box-one:hover .box-two {
  height: 100%;
}
.box-one:hover .layer {
  display: none;
}
.box-one .box-two {
  height: 0;
}
.box-one .layer {
  display: block;
} */

.main {
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; 
}

.main .card-box {
  width: 25%;
  position: relative;
  cursor: pointer;
}

.main .card-box .inner {
  position: relative;
  width: 100%;
}

.main .card-box .inner .detail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.main .card-box .inner .detail h1.card-title {
  margin-bottom: 0;
  color: #fff;
}

.main .card-box .slideUp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  background-color: rgba(0, 66, 186, 0.8);
  overflow: hidden;
  transition: all ease-in-out .6s;
}

.main .card-box .slideUp .main-slide {
  text-align: center;
}

.main .card-box .slideUp .main-slide span svg {
  color: #fff;
}

.main .card-box .slideUp .main-slide h1 {
  color: #fff;
}

.main .card-box .slideUp .main-slide p.description {
  color: #fff;
}

.main .card-box .slideUp .main-slide .explore-btn button {
  color: #fff;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  background: transparent;
  text-transform: capitalize;
}

.main .card-box:hover .slideUp {
  height: 100%;
}

.main .card-box:hover h1.card-title {
  display: none;
}

@media (min-width: 0px) and (max-width: 480px) {
  .main .card-box {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .main .card-box {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .main .card-box {
    width: 33.33%;
  }
}