.web-main {
    padding: 40px 0;
}

.web-heading {
    text-align: center;
}

.web-heading h1 {
    color: #333;
    font-size: 28px;
    line-height: 1.2;
    font-family: 'Roboto',sans-serif;
    Font-weight: normal;
    margin: 5px 0 10px 0;
    letter-spacing: 1px;
}

.web-heading h1 a {
    text-decoration: none;
}

.web-heading h1 a:hover {
    text-decoration: underline;
}

.dec-line {
    width: 60px;
    margin: 20px auto;
    height: 1px;
    background-color: #1774fd;
}

.web-heading p {
    font-size: 14px;
    font-style: italic;
    letter-spacing: .075em;
    color: #666;
    font-weight: 500;
}

.web-main .main-component {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.web-main .main-component .items {
    width: 49%;
    position: relative;
    margin-bottom: 30px;
}

.items .icon i.bi {
    font-size: 35px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    color: #1774fd;
}

.main-component .items .details {
    padding-left: 55px;
}

.details h4 {
    margin: 0 0 17px;
    font-size: 18px;
    color: #424242;
    font-weight: 700;
}

.details p {
    font-size: 15px;
    color: #666;
    font-weight: 400;
    margin: 0 0 16px;
}

@media (max-width: 767px) {
    .web-heading h1 {
        font-size: 22px;
    }

    .web-main .main-component .items {
        width: 100%;
        margin-bottom: 20px;
    }
}