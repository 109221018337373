.round-arrow-styling{
    width:90px;
    height: 90px;
    z-index: 1;
    top: 45%;
    cursor: pointer;
}
.round-arrow{
    max-width: 100%;
}
.left-round-arrow-styling{
    cursor: pointer;
    background: #000;
    background: rgba(0,0,0,.5);
     width: 45px;
    height: 51px;
    /* max-width: fit-content; */
    /* max-height: fit-content; */
    margin-left: 1em;
    position: absolute;
    display: block;
    z-index: 1000;
    left: 0;
    color: white;
    
}
.right-round-arrow-styling{
    cursor: pointer;
    background: #000;
    background: rgba(0,0,0,.5);
     width: 45px;
    height: 51px;
    /* max-width: fit-content; */
    /* max-height: fit-content; */
    margin-right: 1em;
    position: absolute;
    display: block;
    z-index: 1000;
    color: white;
    right: 0;
    padding-right: 1em;
}
.homepage-container-styling{
    /* border: 12px solid red; */
    height: 94vh;
    
}
@media (max-width:768px) {
    .homepage-container-styling{

        height:100vh;
    }
}
.banner-contant h1{
    font-size: 4vw;
}
@media (max-width:1024px){
    .left-round-arrow-styling{
        width: 21px;
    height: 30px;
    }
    .right-round-arrow-styling{
        width: 21px;
    height: 30px;
    }
    
.svg-arrow{
    height: 30px;
    width: 21px;
}
}