.web-app-development-container{
    background-image: url(../../../public/images/web-app-banner.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 80vh;
}
.custom-web-development-company{
    background-color: #3a67b6;
    color: white;
}
.padding-svg{
    padding: 24px;
    border-radius:50% ;
    transition: all ease .4s;
}

.padding-svg svg {
    width: 60px;
    height: 60px;
}

.padding-svg:hover {
    transform: scale(1.1);
}
.glass-morphism{
    background: rgba( 255, 255, 255, 0.05 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 50%;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.padding-right{
    padding-right: 18px;
}
.padding-left{
    padding-left: 18px;
}
.border-in-right{
    border-right: 2px solid red;
}
.block-1{
    background-color: #3a67b6;;
}
.block-2{
    background-color: #10a37e;
}

.m-bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
}

@media (min-width: 800px) and (max-width: 992px) {
    .change {
        max-width: 100%;
    }

    .padding-right h4 {
        font-size: 17px;
    }

    .padding-right button.btn {
        font-size: 14px;
        border:none;
    }

    .padding-right button.btn:hover {
        text-decoration: underline;
    }

    .padding-left h4 {
        font-size: 17px;
    }
}

@media (min-width: 576px) and (max-width: 799px) {
    .change {
        max-width: 100%;
    }

    .padding-right h4 {
        font-size: 17px;
    }

    .padding-right button.btn {
        font-size: 14px;
        border:none;
    }

    .padding-right button.btn:hover {
        text-decoration: underline;
    }

    .padding-left h4 {
        font-size: 17px;
    }
}

@media (max-width:575px) {
    .rchange {
        order:3;
    }

    .lchange {
        order: 2;
        padding-top: 50px;
    }

    .cchange {
        order: 1;
    }
}