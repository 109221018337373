.title{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
    color: white;
    font-size: 90px;
    font-weight: 700;
    /* text-decoration: none; */
    /* border: 1px solid red; */
    /* transition: text-decoration 2s; */
}
.image-responsive{
  @media (max-width:768px) {
    min-width: 100%;
  }
}
/* .title:hover{
    text-decoration: underline;
}
  
  .title::after {
    content: '';
    width: 0px;
    height: 12px;
    display: block;
    background: white;
    transition: 300ms;
  }
  
  .title:hover::after {
    width: 45%;
  } */







  #banner{ width:100%; display:inline-block; height: 100%; position:relative; }
#banner:before{ width: 100%; height: 100%; position: absolute; top: 0; left: 0; content: ""; background: rgba(0,0,0,0.65); }
#banner img{ width:100%; height:auto; }

.banner-contant{ width:100%; position:absolute; top:50%; left:0px; transform:translateY(-50%);}


.banner-contant h1{ width:100%; margin:0px 0 15px; padding:0; font-size:42px; color:#fff; }
.banner-contant h1 span{ display: inline-block; position: relative; padding-bottom: 15px; transform: scale(1);
	transition: transform 0.5s ease, opacity 1s ease; }
.banner-contant h1 span:after{ width: 100%; position: absolute; bottom: 0px; left: 0px; height: 2px; background: #fff; content: ""; 
	transition: width 0.4s ease;
	transition-delay: 0.8s; }
.banner-contant h3{ width:100%; margin:0px; padding:0px; color:#fff;  }

.is-loading .banner-contant span:before,
.is-loading .banner-contant span:after {
	width: 0;
}