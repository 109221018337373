.line-divider {
  padding: 0;
  /* border: 8px solid red; */
  width: 161px;
}
.card-description {
  margin: 0;
}
.line-divider-box {
  margin-top: 1px;
  margin-bottom: 12px;
}
