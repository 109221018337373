.services-container{
    width: 90%;
    padding: 2em;
    height: 450px;
}
.categories-row{
    border-bottom: 2px solid red;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 12px;
}
.categories-row span{
    cursor: pointer;
}
.column-1{
    border-right: 2px solid red;
}

.ordered-list li{
    list-style-type: none;

}
.column-title{
    font-weight: 700;
}
.consultation-container{
    padding: 12px;
    border-radius: 12px;
    background: rgb(22,209,231);
background: radial-gradient(circle, rgba(22,209,231,1) 0%, rgba(19,151,214,1) 62%);
display: flex;
justify-content: space-between;
align-items: center;
}
.consultation-button{
    background-color: #12408c;
    color: white;
    font-weight: 700;
}