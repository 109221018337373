.bottom-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  height: 4.1em;
  padding: 0;
  background-color: #12408c;
  z-index: 10;
  /* border-radius: 12px; */
  border: 1px solid rgba(209, 213, 219, 0.3);
}
.menu-text-container {
  display: flex;
  justify-content: space-between;
}
/* .menu-options-link:hover{

} */
/* .dropdown-custom-styling{
    top: 0;
    z-index: 2;
} */
.logo-container {
  margin-left: auto;
  padding: 8px 14px;
  /* padding-right: 14px; */
  width: 150px;
  height: inherit;
  background-color: #faf9f6;
}
.anchor-link-text {
  height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: black;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}
.icon-title {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 700;
  color: #12408c;
  cursor: pointer;
}
.anchor-link-text:hover {
  /* background-color:#20a3f0 ; */
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(53, 53, 203, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
}
.dropdown-menu-box-container {
  /* border: 1px solid red; */
  position: absolute;
  min-width: 20%;
  max-width: 100%;
  background-color: #edeade;
  padding-top: 12px;
  border-left: 6px solid rgb(18, 64, 140);
  border-right: 6px solid rgb(18, 64, 140);
  border-top: 6px solid rgb(18, 64, 140);
  overflow: hidden;
  /* backdrop-filter: blur(6px) saturate(200%);
    -webkit-backdrop-filter: blur(6px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.05); */
  /* border-radius: 12px; */
  /* border: 1px solid rgba(209, 213, 219, 0.3); */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  /* border: 1px solid rgba(209, 213, 219, 0.3); */
  /* padding: 12px; */
  color: black;
  /* box-shadow: 0px 0px 13px black; */
  bottom: 4.06em;
  /* backdrop-filter: blur(6px) saturate(200%);
    -webkit-backdrop-filter: blur(6px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.38); */
  /* background-color: white; */
  /* background-color: white; */
  left: 0;
  z-index: 0;
  /* top: 0; */
  /* display: inline-block; */
}
.svg-styling {
  color: white;
}
.icon-container {
  width: 20%;
}
.width-40 {
  width: 40%;
  /* border: 12px solid black; */
}
.horizontal {
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 0;
}
.menu-options-styling {
  color: white;
}
.svg-container {
  /* border: 1px solid red; */
  padding: 14px;
}
.svg-container:hover {
  /* backdrop-filter: blur(0px) saturate(200%);
  -webkit-backdrop-filter: blur(0px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.02); */
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  color: white;
  /* border: 1px solid rgba(209, 213, 219, 0.3); */
}
.hamburger-bar-container {
  display: flex;
  flex-direction: column;
}
.hamburger-bar {
  width: 1.5em;
  height: 0.2em;
  /* margin: 0 1em;
    padding: 0 1em; */
  margin-bottom: 0.2em;
  background-color: white;
  display: none;
}
@media (max-width: 1024px) {
  .menu-text-container {
    display: none;
  }
  .hamburger-bar {
    display: block;
  }
  .bottom-navbar-container {
    padding: 0 4px;
  }
  /* .logo-container{
        margin-right: auto;
        width: 40%;
    } */
}
@media (max-width: 1024px) and (min-width: 320px) {
  .logo-container {
    margin: 0;
    padding: 8px 14px;
    /* padding-right: 14px; */
    width: 150px;
    height: inherit;
    background-color: #faf9f6;
  }
}
@media (max-width: 320px) {
  .logo-container {
    margin-right: auto;
    padding: 8px 14px;
    /* padding-right: 14px; */
    width: 150px;
    height: inherit;
    background-color: #faf9f6;
    margin: 0;
  }
}
