.design-container {
    background-color: #22b3ec;
    padding: 100px 0;
}

.inner-design {
    padding-bottom: 400px;
}

.design-heading {
    text-align: center;
}

.design-heading h1 {
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 20px;
}

.design-heading .c-icons {
    margin-bottom: 15px;
}

.design-heading .c-icons span i {
    color: #fff;
    margin: 0 2px;
}

p.design-para {
    margin: 0 auto;
    width: 75%;
    color: #fff;
    line-height: 30px;
}

@media (max-width: 767px) {
    .design-container {
        padding: 50px 0;
    }

    .design-heading h1 {
        font-size: 34px;
    }

    p.design-para {
        width: 100%;
        line-height: 25px;
    }
}

@media (max-width: 410px) {
    .design-container {
        padding: 50px 0;
    }

    .inner-design {
        padding-bottom: 540px;
    }

    .design-heading h1 {
        font-size: 34px;
    }

    p.design-para {
        width: 100%;
        line-height: 25px;
    }
}